





























import { Mixin } from "@/core/mixins/mixin";
import { Row, Image, List, Icon, Empty, Loading } from "vant";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import recommendItem from "@/components/Yuboya/recommendItem.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [List.name]: List,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Loading.name]: Loading,
    recommendItem,
  },
})
export default class RecommendYuboya extends Mixins(Mixin) {
  @Prop({ default: "", type: String })
  businessId!: string;
  @Prop({ default: "", type: String })
  businessType!: string;
  public recommendYuboya: Array<cms.YbyContentListVO> = [];
  loading = false;
  @Watch("businessId")
  changeId() {
    console.log("推荐娱伯牙：-----------", this.businessId);
    this.initYuboya();
  }
  initYuboya(): void {
    this.loading = true;
    this.$api.cmsApi.ybyContent.getYbyRelationList(
      this.businessId,
      this.businessType,
      ({ data }) => {
        this.recommendYuboya = data;
        this.loading = false;
      }
    );
  }
  toPageYuboya(): void {
    window.location.href = location.origin + "/third/#/yuboya";
  }
}
