































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Icon, Button, Image } from "vant";
import CommentItem from "@/components/CommentItem.vue";
@Component({
  components: {
    Mixin,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Image.name]: Image,
    CommentItem,
  },
})
export default class ProductComment extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public productId!: string;
  @Prop({ default: "01", type: String }) public businessType!: string;
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public commentShowList?: Array<member.ProductCommentDTO>;
  @Prop({ default: 0, type: Number }) public commentTotal?: number;
}
